import * as css from './Grid.styles'
import { IGrid } from './Grid.types'

const Grid = ({
  children,
  breakpoint = 'xs',
  gap = 1,
  minWidth = 12,
  alignItems,
  columns,
  margin,
  padding,
  styles,
  width
}: IGrid) => (
  <div
    data-testid='grid'
    css={css.root({
      alignItems,
      breakpoint,
      columns,
      gap,
      margin,
      minWidth,
      padding,
      styles,
      width
    })}
  >
    {children}
  </div>
)

export default Grid
