import { CSSProperties } from 'react'

import { createStyles } from 'utils/css'
import { ISize } from 'utils/types'

import { IGridStyles } from './Grid.types'

const customMediaQuery: (
  gap: number,
  minWidth: number,
  sizeFn: ISize
) => {
  [n: string]: CSSProperties
} = (gap, minWidth, sizeFn) => ({
  '@media all and (-ms-high-contrast:none)': {
    display: '-ms-flexbox',
    flexWrap: 'wrap',
    margin: sizeFn(-gap / 2),

    '& > *': {
      margin: sizeFn(gap / 2),
      flexBasis: sizeFn(minWidth),
      flexShrink: 0,
      flexGrow: 1
    }
  }
})

export const root = ({
  alignItems,
  breakpoint,
  columns,
  gap,
  margin,
  minWidth,
  padding,
  styles,
  width
}: IGridStyles) =>
  createStyles(({ mediaQuery, size, spacing }) => {
    const defaultStyles: CSSProperties = {
      maxWidth: width && size(width),
      margin: !margin ? 'auto' : spacing(margin),
      padding: spacing(padding),
      display: 'grid',
      columnGap: size(gap),
      rowGap: size(gap),
      alignItems: alignItems && alignItems,
      [mediaQuery(breakpoint)]: {
        gridTemplateColumns: columns && columns
      },
      ...customMediaQuery(gap, minWidth, size)
    }
    return { ...defaultStyles, ...styles }
  })
